import React from "react";
import "./style.css";

export const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "20px" }}>
      <div className="privacy-policy" style={{ padding: "20px" }}>
        <div
          onClick={() => window.history.back()}
          className="privacy-back-button"
        >
          Go back
        </div>
      </div>
      <h1>Privacy Policy</h1>
      <p>
        MEDINGEN recognizes the value of your privacy of your information. The
        Policy describes how we treat the information collected on the platform
        and other sources. It contains about the rights and how you can contact
        MEDINGEN about privacy practices. Upon using the Platform, the user
        agrees to the Policy.
      </p>

      <h2>1. Definitions</h2>
      <p>
        1.1 <strong>Data</strong> – Personal, non-personal, and sensitive
        information collected when you used the platform.
        <br />
        1.2 <strong>Cookies</strong> - They are small pieces of text sent to
        your browser by a website you visit which records the actions and
        preferences of the users.
        <br />
        1.3 <strong>Data Protection Laws</strong> – All laws that govern data
        usage.
        <br />
        1.4 <strong>User/You</strong> – A natural person who accesses the
        platform.
      </p>

      <h2>2. Information collected</h2>
      <p>
        2.1 <strong>Personal information</strong> – Name, sex, date of birth,
        demography, health conditions, medications, phone number, e-mail
        address, postal address, and other similar information.
        <br />
        2.2 <strong>Financial information</strong> – Payment instrument,
        transaction preferences, transaction methods, financial behaviour, and
        other similar information.
        <br />
        2.3 <strong>Technical information</strong> – IP address, website and
        application usage, cookies, and other similar information.
        <br />
        2.4 <strong>Product and Service information</strong> – Login details,
        account details, product and service orders, product and service
        requests in platform, or in any other marketing programs.
      </p>

      <h2>3. Information collected in different ways</h2>
      <h3>3.1 Directly from you:</h3>
      <p>
        3.1.1 Details shared during registration in the platform.
        <br />
        3.1.2 During payment or any transactions carried on the platform.
        <br />
        3.1.3 Voluntarily provided in survey conducted on platform.
        <br />
        3.1.4 Opted to receive the promotional offers.
      </p>

      <h3>3.2 Collected passively</h3>
      <p>
        3.2.1 We automatically collect certain information when the user uses
        the platform which helps us in making improvements in the platform.
        <br />
        3.2.2 Our web servers or affiliates provide analytics and performance
        enhancement services, collecting IP address, OS details, device details,
        and language settings. This information is used to measure the average
        visits on the platform and pages viewed.
        <br />
        3.2.3 We may collect your data automatically via cookies and similar
        technologies.
      </p>

      <h2>4. Use of Data</h2>
      <p>
        The data collected, except for the information received from Google
        APIs, may be used for the following purposes:
      </p>
      <p>
        4.1 To carry out our obligations arising from any contract entered into
        between you and us.
        <br />
        4.2 To provide products and/or services and communicate with you about
        products and/or services offered by us.
        <br />
        4.3 To provide you with offers (including for financial products and/or
        services), personalized services and recommendations, and to improve
        your experience on our website and mobile application.
        <br />
        4.4 To operate, evaluate and improve our business, website, and mobile
        application.
        <br />
        4.5 To generate aggregated data to prepare insights to enable us to
        understand customer behavior, patterns, and trends with a view to
        learning more about your preferences or other characteristics.
        <br />
        4.6 To provide privileges and benefits to you, marketing and promotional
        campaigns based on your profile.
        <br />
        4.7 To communicate with you (including to respond to your requests,
        questions, feedback, claims, or disputes) and to customize and improve
        our services.
        <br />
        4.8 To enforce the terms of use of our website and mobile application.
        <br />
        4.9 To protect against and prevent fraud, illegal activity, harm,
        financial loss, and other legal or information security risks.
        <br />
        4.10 To serve other purposes for which we provide specific notice at the
        time of collection, and as otherwise authorized or required by
        applicable law.
        <br />
        4.11 We treat these inferences as personal information (or sensitive
        personal information, as the case may be), where required under
        applicable law.
      </p>

      <h2>5. Sharing of Data</h2>
      <p>
        MEDINGEN may share, disclose the information except for the information
        received from the Google APIs, for the purposes that include the
        following:
      </p>
      <p>
        5.1 <strong>Business transfers</strong>
        <br />
        We may sell or buy business subsidiaries, your data maybe transferred as
        a part of such transaction. Any data that we will receive from a third
        party shall be in accordance with the privacy policy.
      </p>
      <p>
        5.2 <strong>Service Providers</strong>
        <br />
        MEDINGEN may share the data with service providers for various analysis.
      </p>
      <p>
        5.3 MEDINGEN may release the data whenever necessary to protect the
        interests of individuals, for any investigations, protection from
        fraudulent or illegal activity in accordance with the law.
      </p>
      <p>
        5.4 We may share data with Third Parties:
        <br />
        a. Upon your request or authorization
        <br />
        b. To comply with applicable law, to respond to legal process
        <br />
        c. To operate and maintain the security of our platform or to prevent
        any attack on our system
      </p>

      <h2>6. Minors</h2>
      <p>
        The website and mobile application only entertain users above 18 years
        of age from using the platform.
      </p>

      <h2>7. Data Security</h2>
      <p>
        MEDINGEN takes utmost care in safeguarding the data, and we store your
        data in servers. If you suspect any misuse, loss, or unauthorized access
        to your data, please inform us immediately.
      </p>

      <h2>8. Retention of Data</h2>
      <p>
        MEDINGEN retains data as long as you use our products and services or
        access the platform to comply with any legal obligations. The retention
        of data is necessary and is permissible by law.
      </p>
      <p>
        The closure of your account does not obligate us to retain the data, and
        we delete the data without further obligation. However, we may retain
        the data if we believe it is necessary to prevent any abuse or as
        required by law.
      </p>

      <h2>9. Rights</h2>
      <p>
        We process the data with your consent, and you have the following rights
        concerning the data:
      </p>
      <p>
        9.1 Right to access, review, and modify the data.
        <br />
        9.2 Right to correction.
        <br />
        9.3 Right to withdraw consent.
      </p>

      <h2>10. App Permissions</h2>
      <p>
        10.1 Location
        <br />
        10.2 Camera
        <br />
        10.3 Photos/Media/Files
        <br />
        10.4 SMS
        <br />
        10.5 Access WIFI state
        <br />
        10.6 Record Audio
        <br />
        10.7 Bluetooth
      </p>

      <h2>11. Compliance with Google User Data Policy</h2>
      <p>
        MEDINGEN’s use of information received from Google APIs will adhere to
        the Google API Services User Data Policy, including the Limited Use
        requirements.
      </p>

      <h2>12. Severability</h2>
      <p>
        If a court of law deems any provision of the Policy in contravention to
        the laws of the land, only that part of the policy will be deemed
        invalid.
      </p>

      <h2>13. Amendments to the Policy</h2>
      <p>
        Whenever necessary, MEDINGEN changes the policy in accordance with the
        applicable laws. You are deemed to have accepted the updated policy, and
        we request you to review the Privacy Policy frequently.
      </p>

      <h2>14. Contact Us</h2>
      <p>
        To exercise your rights as mentioned in Clause 9 of the Policy, you can
        reach us at support@medingen.in.
      </p>

      <h2>15. Grievance Officer</h2>
      <p>
        Name: Ms. Ramyashree Girish
        <br />
        E-mail: grievancemig@gmail.com
      </p>
    </div>
  );
};

export const GrievanceRedressalPolicy = () => {
  return (
    <div className="grievance" style={{ padding: "20px" }}>
      <div
        onClick={() => window.history.back()}
        className="privacy-back-button"
      >
        Go back
      </div>

      <h1>Grievance Redressal Policy</h1>

      <h2>1. Policy Overview</h2>
      <p>
        The policy sets out MEDINGEN's grievance redressal process to resolve
        grievances raised by consumers using the platform.
      </p>

      <h2>2. Company Details</h2>
      <ul>
        <li>
          <strong>Legal Entity Name:</strong> MEDINGEN
        </li>
        <li>
          <strong>Corporate Office:</strong> No.16, Ground Floor, School Street,
          Mangadu, Chennai 600 122
        </li>
        <li>
          <strong>Registered Office:</strong> No.16, Ground Floor, School
          Street, Mangadu, Chennai 600 122
        </li>
        <li>
          <strong>Website Name:</strong>{" "}
          <a
            href="https://www.medingen.in"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.medingen.in
          </a>
        </li>
        <li>
          <strong>Website Details:</strong> E-commerce healthcare technology
          platform
        </li>
      </ul>

      <h2>3. Contact Information</h2>
      <p>In case of any query or complaint, feel free to contact us:</p>
      <ul>
        <li>
          <strong>Email:</strong>{" "}
          <a href="mailto:support@medingen.in">support@medingen.in</a>
        </li>
        <li>
          <strong>Phone Number:</strong> 709 0123 709
        </li>
      </ul>

      <h2>4. Grievance Redressal Mechanism</h2>
      <p>
        If you are not satisfied with the resolution provided by our customer
        care, you may escalate the issue to the grievance officer.
      </p>

      <h3>Steps for Redressal:</h3>
      <ol>
        <li>The consumer may connect via telephonic conversation or e-mail.</li>
        <li>
          The grievance officer will acknowledge the receipt of the complaint
          within 48 hours.
        </li>
        <li>
          A unique ID will be provided to track the status of the complaint.
        </li>
        <li>
          The issue will be addressed within 1 month from the date of receipt of
          the complaint.
        </li>
      </ol>

      <h3>Grievance Officer:</h3>
      <ul>
        <li>
          <strong>Name:</strong> Ms. Ramyashree Girish
        </li>
        <li>
          <strong>Email:</strong>{" "}
          <a href="mailto:grievancemig@gmail.com">grievancemig@gmail.com</a>
        </li>
      </ul>

      <p>
        The complaint will be considered closed if the customer care or
        grievance officer has addressed the issue to the customer's satisfaction
        and the customer accepts the closure of the complaint.
      </p>
    </div>
  );
};

export const TermsAndConditions = () => {
  return (
    <div className="terms-conditions" style={{ padding: "20px" }}>
      <div
        onClick={() => window.history.back()}
        className="privacy-back-button"
      >
        Go back
      </div>

      <h1>Terms and Conditions</h1>

      <h2>GENERAL</h2>
      <ol>
        <li>
          The domain{" "}
          <a
            href="https://www.medingen.in"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.medingen.in
          </a>{" "}
          is a platform and mobile application (collectively referred to as
          “platform”), owned and operated by Medingen, a proprietorship firm,
          with its registered office at No. 16, Ground Floor, School Street,
          Mangadu, Chennai - 600122. Hereinafter referred to as MEDINGEN.
        </li>
        <li>
          For the purposes of these Terms and Conditions, “You” or “User” refers
          to any natural or legal person who accesses, uses, or registers on the
          platform.
        </li>
        <li>
          These Terms and Conditions are legally binding between you (the user)
          and MEDINGEN. By accessing the platform, you agree to be bound by
          them. If you do not agree, please refrain from accessing the platform.
        </li>
        <li>
          These Terms and Conditions may be updated regularly by MEDINGEN. Your
          continued use of the platform signifies your acceptance of any
          modifications. Please review the Terms and Conditions regularly.
        </li>
        <li>
          By using or accessing the platform, you are legally bound by the Terms
          and Conditions and other policies available on the platform.
        </li>
        <li>
          This platform facilitates the following services provided by third
          parties:
          <ol type="a">
            <li>Purchase and sale of medicines by Third-Party Pharmacies</li>
            <li>Diagnostic services by Third-Party Diagnostic Centres</li>
            <li>
              Online medical consultancy services by Third-Party Medical
              Practitioners
            </li>
            <li>Promotional activity by Third-Party Advertisers</li>
            <li>Services by Third-Party Service Providers</li>
            <li>Information Services</li>
          </ol>
          Hereinafter collectively referred to as the “Third Party.” The
          arrangement between You, MEDINGEN, and the Third Party shall be in
          accordance with these Terms and Conditions.
        </li>
        <li>
          You must register with the MEDINGEN platform to access the services
          provided by MEDINGEN.
        </li>
        <li>
          You must be at least 18 years of age to access the platform, and by
          accessing the platform, you confirm that you are 18 years or older and
          agree to these Terms and Conditions.
        </li>
        <li>
          MEDINGEN reserves the right to alter these Terms and Conditions at its
          discretion. Any modifications will be updated on the platform and
          notified to you.
        </li>
        <li>
          The Terms and Conditions are governed by the following laws:
          <ul>
            <li>Indian Contract Act, 1872</li>
            <li>
              Information Technology Act, 2000, and respective rules,
              regulations, guidelines
            </li>
            <li>
              Information Technology (Reasonable Security Practices and
              Procedures and Sensitive Personal Information) Rules, 2011
            </li>
            <li>
              Information Technology (Intermediaries Guidelines) Rules, 2011
            </li>
            <li>
              The Drugs and Cosmetics Act, 1940 read with Drugs and Cosmetics
              Rules, 1945
            </li>
            <li>
              The Drugs and Magic Remedies (Objectionable Advertisement) Act,
              1954
            </li>
            <li>
              Indian Medical Council Act, 1956 read with Indian Medical Council
              Rules, 1957
            </li>
            <li>Pharmacy Act, 1948</li>
            <li>
              Consumer Protection Act, 2019 read with Consumer Protection
              E-Commerce Rules, 2020
            </li>
            <li>Data Protection Act, 2023</li>
          </ul>
        </li>
        <li>
          The platform’s contents are only for authorized usage, including
          accessing the platform, placing orders, receiving orders, and
          communicating for these purposes. Using the platform outside of these
          agreed terms constitutes a violation of this contract.
        </li>
        <li>
          All content on the platform, including text, images, logos, designs,
          graphics, and software, is protected under respective laws.
          Unauthorized use amounts to infringement of Intellectual Property
          Rights and other applicable laws.
        </li>
      </ol>
    </div>
  );
};

export const ReturnRefundCancellationPolicy = () => {
  return (
    <div className="return-policy" style={{ padding: "20px" }}>
      <div
        onClick={() => window.history.back()}
        className="privacy-back-button"
      >
        Go back
      </div>

      <h1>Return, Refund, and Cancellation Policy</h1>

      <h2>RETURN POLICY</h2>

      <h3>1. Return of Products</h3>
      <p>
        (a) We take utmost care in delivering products as per your order. Only
        products eligible for return can be returned within 5 days from the date
        of delivery.
      </p>
      <p>
        (b) A product can only be returned if it meets one of the following
        conditions:
      </p>
      <ul>
        <li>Incorrect product</li>
        <li>Incomplete order</li>
        <li>Expired product</li>
        <li>Damaged products</li>
      </ul>
      <p>
        It is the user’s responsibility to thoroughly check the product. Please
        do not accept products if the seal of the package is tampered with in
        transit. Acceptance of a damaged product will make it ineligible under
        the return policy.
      </p>

      <h3>2. Products Not Eligible for Return</h3>
      <p>
        (a) The following categories and product types do not qualify for
        return:
      </p>
      <table border="1" cellPadding="10" cellSpacing="0">
        <thead>
          <tr>
            <th>Category</th>
            <th>Type of Products</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Baby Care</td>
            <td>
              Bottle Nipples, Breast Nipple Care, Breast Pumps, Diapers, Ear
              Syringes, Nappy, Wet Reminder, Wipes, Wipe Warmers
            </td>
          </tr>
          <tr>
            <td>Food and Nutrition</td>
            <td>Health Drinks, Health Supplements</td>
          </tr>
          <tr>
            <td>Healthcare Devices</td>
            <td>
              Glucometer Lancet/Strip, Healthcare Devices and Kits, Surgical,
              Health Monitors
            </td>
          </tr>
          <tr>
            <td>Sexual Wellness</td>
            <td>
              Condoms, Fertility Kit/Supplement, Lubricants, Pregnancy Kits
            </td>
          </tr>
          <tr>
            <td>Temperature Controlled and Specialty Medicines</td>
            <td>
              Vials, Injections, Vaccines, Penfills, or any product requiring
              cold storage, or those that fall under the category of specialty
              medicines
            </td>
          </tr>
        </tbody>
      </table>

      <h3>3. Return Process</h3>
      <p>(a) To return a product, please contact MEDINGEN:</p>
      <ul>
        <li>
          <strong>Email:</strong>{" "}
          <a href="mailto:support@medingen.in">support@medingen.in</a>
        </li>
        <li>
          <strong>Phone:</strong> 709 0123 709
        </li>
      </ul>
      <p>
        (b) The MEDINGEN team will verify if the claim meets the eligibility
        criteria mentioned above.
      </p>
      <p>(c) Upon satisfaction, the return process will be initiated.</p>
      <p>
        (d) The customer shall return the package through the nearest courier
        service using the TOPAY option.
      </p>

      <h2>REFUND POLICY</h2>
      <p>
        1. Refunds will be initiated only upon confirmation by the collection
        agent after the reverse pickup.
      </p>
      <p>
        2. The amount will be refunded either as MIG cashback or to the original
        source account.
      </p>

      <h2>CANCELLATION POLICY</h2>
      <p>
        The cancellation of an order by the user will only be accepted if the
        cancellation is made before the shipment of the products.
      </p>
    </div>
  );
};

export default ReturnRefundCancellationPolicy;
